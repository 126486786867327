import React from "react";

const BuildTools = ({ className }) => (
  <svg
    width="125px"
    height="123px"
    viewBox="0 0 125 123"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g id="Diensten_Iconen_-03" fillRule="nonzero">
        <path
          d="M58.52,66.94 C58.1241548,66.9401597 57.7438627,66.7858903 57.46,66.51 L23.37,33.51 L10.08,24.6 C9.93107412,24.4943321 9.79949978,24.3661315 9.69,24.22 L0.49,11.34 C0.0638100242,10.7387121 0.126862338,9.91903197 0.64,9.39 L8.15,1.63 C8.66045063,1.09936596 9.47782422,1.01088737 10.09,1.42 L23.26,10.19 C23.4134557,10.2901231 23.5459255,10.4191962 23.65,10.57 L33,23.57 L67.09,56.57 C67.4812059,56.9487015 67.6406601,57.507342 67.5082979,58.035487 C67.3759357,58.563632 66.971866,58.9810436 66.4482979,59.130487 C65.9247298,59.2799304 65.3612058,59.1387016 64.97,58.76 L30.78,25.67 C30.7121808,25.6108091 30.6517448,25.5436579 30.6,25.47 L21.34,12.6 L9.46,4.69 L3.71,10.62 L12.01,22.24 L25.18,31.07 C25.2561765,31.1185804 25.3266229,31.1756085 25.39,31.24 L59.58,64.33 C60.0311448,64.7601949 60.1738871,65.4222389 59.9400907,66.000113 C59.7062943,66.5779872 59.1433608,66.9545287 58.52,66.9500404 L58.52,66.94 Z"
          id="Path"
        />
        <path
          d="M107.84,122.76 C103.399129,122.782337 99.1239598,121.075138 95.92,118 L67.33,90.34 C58.27,81.57 54.24,69.55 54.07,69.04 C53.8916313,68.5046646 54.0266261,67.9145446 54.42,67.51 L67.94,53.51 C68.3318742,53.1026997 68.9184021,52.9483502 69.46,53.11 C69.98,53.26 82.12,56.89 91.18,65.66 L119.76,93.32 C124.780839,98.1762991 126.352387,105.593619 123.732415,112.068818 C121.112443,118.544018 114.82516,122.781525 107.84,122.78 L107.84,122.76 Z M57.28,68.89 C58.36,71.75 62.28,81.16 69.45,88.1 L98,115.75 C103.594645,121.16239 112.517609,121.014644 117.93,115.42 C123.34239,109.825355 123.194644,100.902391 117.6,95.49 L117.6,95.49 L89.02,67.84 C81.84,60.84 72.32,57.28 69.43,56.3 L57.28,68.89 Z"
          id="Shape"
        />
        <path
          d="M97,118.38009 C96.601701,118.377585 96.2192087,118.223873 95.93,117.95 C95.3349913,117.352929 95.3349913,116.387071 95.93,115.79 L117.66,93.34 C118.038702,92.9487941 118.597342,92.7893399 119.125487,92.9217021 C119.653632,93.0540643 120.071044,93.458134 120.220487,93.9817021 C120.36993,94.5052702 120.228702,95.0687942 119.85,95.46 L98.12,117.91 C97.827509,118.214492 97.4221914,118.384581 97,118.38009 L97,118.38009 Z"
          id="Path"
        />
        <path
          d="M98.87,98.9400255 C98.4737391,98.9423081 98.0926642,98.787721 97.81,98.51 L81.81,83 C81.2024868,82.4118167 81.1868167,81.4425132 81.775,80.835 C82.3631833,80.2274868 83.3324868,80.2118167 83.94,80.8 L99.94,96.32 C100.380629,96.7539847 100.516648,97.4105655 100.284695,97.9838841 C100.052741,98.5572027 99.4984385,98.934493 98.88,98.9400255 L98.87,98.9400255 Z"
          id="Path"
        />
        <path
          d="M66,83.88 C65.6049661,83.8760541 65.2261409,83.7223798 64.94,83.45 C64.3449913,82.8529289 64.3449913,81.8870711 64.94,81.29 L84,61.55 C84.3787015,61.1587941 84.937342,60.9993399 85.465487,61.1317021 C85.993632,61.2640643 86.4110436,61.668134 86.560487,62.1917021 C86.7099304,62.7152702 86.5687016,63.2787942 86.19,63.67 L67.09,83.41 C66.8047208,83.7069676 66.4117778,83.8764017 66,83.88 L66,83.88 Z"
          id="Path"
        />
        <path
          d="M76,58.81 C75.3841526,58.8044924 74.8316425,58.4302697 74.5980281,57.8604253 C74.3644137,57.2905808 74.4952421,56.6362156 74.93,56.2 L90.2,41.12 C90.6661548,40.6593649 91.3739117,40.5467672 91.96,40.84 C99.5522731,44.6544747 108.73389,43.1799369 114.75,37.18 C119.537278,32.3679326 121.519591,25.4355021 120,18.82 L110.7,28.12 C106.599286,32.2151914 99.9551914,32.2107143 95.86,28.11 C91.7648086,24.0092857 91.7692857,17.3651914 95.87,13.27 L105.2,4 C97.7559838,2.31704627 90.0087478,5.07591242 85.3039992,11.0851595 C80.5992506,17.0944066 79.7799815,25.2773038 83.2,32.1 C83.4964478,32.6855884 83.3835362,33.3953181 82.92,33.86 L62.92,54.11 C62.5377258,54.4958469 61.9786431,54.6484432 61.4533528,54.5103075 C60.9280625,54.3721718 60.5163688,53.9642901 60.3733528,53.4403075 C60.2303367,52.9163249 60.3777258,52.3558468 60.76,51.97 L80,32.45 C76.2972607,24.0921504 77.9468267,14.3305743 84.1902548,7.65348403 C90.4336829,0.976393751 100.062683,-1.32399449 108.65,1.81 C109.147843,1.98405729 109.524037,2.39786991 109.65,2.91 C109.758073,3.42080888 109.599969,3.95158647 109.23,4.32 L98.11,15.44 C96.0438555,17.2667277 95.1576934,20.0865023 95.8074451,22.7667466 C96.4571969,25.446991 98.5362512,27.5479301 101.209545,28.2257109 C103.882839,28.9034917 106.711738,28.0469037 108.56,26 L119.69,14.87 C120.058473,14.4997562 120.590372,14.3430819 121.100712,14.4544656 C121.611052,14.5658492 122.029301,14.9298972 122.21,15.42 C124.750864,22.4056727 123.725182,30.1915328 119.461866,36.2808469 C115.198549,42.3701609 108.233411,45.9976247 100.8,46 C97.6130191,45.9961446 94.4633153,45.3143905 91.56,44 L77.06,58.33 C76.7849788,58.6251823 76.4032692,58.7980319 76,58.81 L76,58.81 Z"
          id="Path"
        />
        <path
          d="M24.75,122.00004 C22.0855829,122.005012 19.4413616,121.537788 16.94,120.62 C16.4356345,120.455915 16.0554164,120.037675 15.94,119.52 C15.8274626,119.010841 15.9819881,118.479424 16.35,118.11 L27.5,107 C28.9654492,105.602537 29.7932601,103.664953 29.79,101.64 C29.79,98.49 27.15,96.54 24.35,94.48 L24.11,94.3 C20.67,91.77 15.03,98.41 14.97,98.48 L14.87,98.59 L5.87,107.59 C5.50216053,107.960385 4.96999269,108.116105 4.46051236,108.002436 C3.95103203,107.888767 3.53552127,107.521613 3.36,107.03 C0.255285667,98.4488771 2.56868211,88.8430881 9.23964421,82.6162762 C15.9106063,76.3894643 25.6527807,74.742338 34,78.43 L52.69,59.76 C53.2954852,59.2219011 54.2154499,59.2489824 54.7882338,59.8217662 C55.3610176,60.3945501 55.3880989,61.3145148 54.85,61.92 L35.39,81.35 C34.9253181,81.8135362 34.2155884,81.9264478 33.63,81.63 C26.8070172,78.2189597 18.6296779,79.0420873 12.6233936,83.7445164 C6.61710926,88.4469454 3.85583098,96.18786 5.53,103.63 L12.68,96.48 C13.39,95.63 20.31,87.69 25.93,91.85 L26.17,92.02 C29.3,94.33 32.85,96.95 32.85,101.64 C32.8573109,104.484778 31.7012014,107.208861 29.65,109.18 L20.36,118.47 C21.8043506,118.793651 23.279833,118.957966 24.76,118.96 C35.6520084,118.948976 44.4789757,110.122008 44.49,99.23 C44.5020987,96.1487747 43.7823555,93.1087164 42.39,90.36 C42.0912112,89.7629731 42.2169712,89.0408676 42.7,88.58 L57.33,74.47 C57.7156955,74.0543558 58.2996344,73.8861058 58.8473575,74.0328046 C59.3950806,74.1795033 59.8167557,74.6170907 59.9430748,75.1698697 C60.0693939,75.7226487 59.8796393,76.2999579 59.45,76.67 L45.6,90.02 C46.889308,92.9187142 47.550464,96.0575011 47.54,99.23 C47.5234532,111.806472 37.3264815,121.994495 24.75,122.00004 Z"
          id="Path"
        />
      </g>
    </g>
  </svg>
);

export default BuildTools;
