import React from "react";
import styled from "styled-components";
import Title from "../atoms/Title";
import { color, font } from "../templates/ui";
import Button from "../atoms/Button";
import ChevronRight from "../atoms/icons/ChevronRight";
import Video from "./Video";
import RouterLink from "../atoms/Link";
import Image from "../atoms/Image";

const StyledTitle = styled(Title)`
  text-transform: uppercase;
  font-weight: 700;
`;

const StyledCultureCard = styled.div`
  .no-right-gutter {
    padding-right: 0;
  }

  .col-lg-5 {
    padding-left: 0;
  }

  .col-xs-12 {
    &:first-child {
      padding-right: 0;
    }

    &:last-child {
      padding-left: 0;

      @media only screen and (min-width: 1200px) {
        padding-left: 20px;
      }
    }
  }
`;

const Text = styled.p`
  margin: 0 0 20px 0;
  font-size: 14px;
  color: #414141;
  font-family: ${font.family.base};
  font-weight: 400;
  line-height: 1.6rem;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }
`;

const StyledChevronRight = styled(ChevronRight)`
  fill: ${color.body.base};
  width: 9px;
  height: 14px;
`;

const ContentWrapper = styled.div`
  margin-bottom: 40px;
  max-width: 100%;

  @media only screen and (min-width: 768px) {
    max-width: none;
  }

  @media only screen and (min-width: 991px) {
    margin-bottom: 0;
  }
`;

const StyledVideo = styled(Video)`
  max-width: 100%;

  @media only screen and (min-width: 768px) {
    max-width: 580px;
  }
`;

const StyledImage = styled(Image)`
  max-width: 100%;

  > div {
    padding-bottom: 66.76%;
  }

  @media only screen and (min-width: 768px) {
    max-width: 580px;
  }
`;

const CultureCard = ({
  title,
  description,
  href,
  hrefExternal,
  videoSrc,
  imageSrc,
}) => (
  <StyledCultureCard>
    <div className="row">
      <div className="col-xs-12 col-md-12 col-lg-5">
        <ContentWrapper>
          <StyledTitle tag="h4" hasLine={true}>
            {title}
          </StyledTitle>
          <Text>{description}</Text>
          {href && (
            <RouterLink href={href} target="_blank">
              <Button icon={<StyledChevronRight />}>Meld je nu aan</Button>
            </RouterLink>
          )}
          {hrefExternal && (
            <a href={hrefExternal} target="_blank" rel="noopener noreferrer">
              <Button icon={<StyledChevronRight />}>Meld je nu aan</Button>
            </a>
          )}
        </ContentWrapper>
      </div>
      <div className="col-xs-12 col-md-12 col-lg-6 col-lg-offset-1 no-right-gutter">
        {videoSrc && <StyledVideo src={videoSrc} />}
        {imageSrc && <StyledImage src={imageSrc} />}
      </div>
    </div>
  </StyledCultureCard>
);

export default CultureCard;
