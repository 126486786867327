import React from "react";
import styled from "styled-components";

const StyledParagraph = styled.p``;

const Paragraph = ({ className, children }) => (
  <StyledParagraph className={className}>{children}</StyledParagraph>
);

export default Paragraph;
