import React from "react";
import styled from "styled-components";
import { color } from "../templates/ui";

const ImageWrapper = styled.div`
  position: relative;
  background-color: ${color.black.alt};
  max-width: 580px;
`;

const ImageContainer = styled.div`
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 0;
  position: relative;

  img {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const Image = ({ className, src }) => (
  <ImageWrapper className={className}>
    <ImageContainer>
      <img src={src} alt="" />
    </ImageContainer>
  </ImageWrapper>
);

export default Image;
