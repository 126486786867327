import React from "react";

const People = ({ className }) => (
  <svg
    width="175px"
    height="109px"
    viewBox="0 0 175 109"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g id="Diensten_Iconen_-02" fillRule="nonzero">
        <path
          d="M126.79,44.93 C126.663761,44.9447244 126.536239,44.9447244 126.41,44.93 L120.23,43.53 L120.23,37.78 L120.68,37.29 C124.469814,33.1923664 126.567142,27.811487 126.55,22.23 L126.55,16.45 C126.55,9.49121245 120.908788,3.85000075 113.95,3.85000075 C106.991212,3.85000075 101.35,9.49121245 101.35,16.45 L101.35,22.23 C101.341471,25.5084898 102.065793,28.7474385 103.47,31.71 C103.867802,32.5673578 103.499288,33.5850694 102.644785,33.9889666 C101.790281,34.3928637 100.769971,34.0316058 100.36,33.18 C98.7448539,29.7560569 97.9114005,26.0157641 97.9199341,22.23 L97.9199341,16.45 C97.9199341,7.59687546 105.096875,0.42 113.95,0.42 C122.803125,0.42 129.980082,7.59687546 129.980082,16.45 L129.980082,22.23 C129.995692,28.4379201 127.748892,34.4388672 123.66,39.11 L123.66,40.79 L127.17,41.58 C128.007671,41.785513 128.5636,42.5796605 128.470017,43.4370815 C128.376435,44.2945026 127.662282,44.9500276 126.8,44.97 L126.79,44.93 Z"
          id="Path"
        />
        <path
          d="M128.51,108.82 C127.563978,108.8145 126.799984,108.046038 126.8,107.1 C126.802257,104.692534 125.567211,102.452835 123.53,101.17 L98.81,90.3 L98.81,82.62 L99.27,82.13 C105.431092,75.4571466 108.84236,66.7021545 108.82,57.62 L108.82,53.34 C109.361302,45.5098079 105.490847,38.0313263 98.7855974,33.9515542 C92.0803475,29.8717821 83.6596525,29.8717821 76.9544026,33.9515542 C70.2491527,38.0313263 66.3786983,45.5098079 66.92,53.34 L66.92,57.62 C66.8978361,66.7069581 70.3129174,75.4661413 76.48,82.14 L76.93,82.63 L76.93,90.3 L52.2,101.17 C50.1666122,102.455224 48.9355791,104.694496 48.94,107.1 C48.9892098,107.743972 48.6735192,108.361236 48.1225866,108.698275 C47.571654,109.035313 46.878346,109.035313 46.3274134,108.698275 C45.7764808,108.361236 45.4607902,107.743972 45.51,107.1 C45.498412,103.45857 47.3958357,100.077361 50.51,98.19 L50.72,98.08 L73.5,88.08 L73.5,84 C67.03505,76.7481008 63.4741738,67.3651919 63.5,57.65 L63.5,53.37 C63.3211852,44.5403671 67.9294349,36.3038393 75.5477107,31.8365957 C83.1659864,27.3693521 92.6040136,27.3693521 100.222289,31.8365957 C107.840565,36.3038393 112.448815,44.5403671 112.27,53.37 L112.27,57.65 C112.293362,67.3617477 108.732647,76.7406701 102.27,83.99 L102.27,88.09 L125.16,98.16 L125.26,98.22 C128.377119,100.104501 130.275568,103.487537 130.260095,107.13 C130.252113,107.586195 130.06329,108.020564 129.735086,108.337515 C129.406882,108.654466 128.966192,108.828026 128.51,108.82 Z"
          id="Path"
        />
        <path
          d="M61.9,94.08 C60.9539618,94.08 60.1855002,93.3160222 60.18,92.37 C60.1972476,90.7510756 59.3765271,89.2382267 58.01,88.37 L39.56,80.25 L39.56,74.13 L40.02,73.64 C44.4831357,68.8040789 46.9547711,62.4606911 46.94,55.88 L46.94,49.14 C46.94,40.8557288 40.2242712,34.14 31.94,34.14 C23.6557288,34.14 16.94,40.8557288 16.94,49.14 L16.94,55.88 C16.9257729,62.4576109 19.3975067,68.7976795 23.86,73.63 L24.32,74.12 L24.32,80.25 L5.86,88.37 C4.49347294,89.2382267 3.67275244,90.7510756 3.69,92.37 C3.62149442,93.2664822 2.87409589,93.9589466 1.975,93.9589466 C1.07590411,93.9589466 0.328505578,93.2664822 0.259757997,92.37 C0.237987099,89.513042 1.71904493,86.854733 4.16,85.37 L4.36,85.27 L20.9,78 L20.9,75.44 C16.1328439,70.0374058 13.5108607,63.0750979 13.53,55.87 L13.53,49.13 C13.3742931,42.4486505 16.8497195,36.2071021 22.6113811,32.8206688 C28.3730427,29.4342355 35.5169573,29.4342355 41.2786189,32.8206688 C47.0402805,36.2071021 50.5157069,42.4486505 50.36,49.13 L50.36,55.87 C50.3913566,63.0904975 47.7725599,70.0715833 43,75.49 L43,78 L59.72,85.37 C62.1609551,86.854733 63.6420129,89.513042 63.620242,92.37 C63.6144998,93.3160222 62.8460382,94.08 61.9,94.08 Z"
          id="Path"
        />
        <path
          d="M173.2,93.94 C172.74475,93.9426623 172.307231,93.7636781 171.984378,93.4427019 C171.661524,93.1217257 171.479992,92.6852581 171.48,92.23 C171.499413,90.6130377 170.682706,89.1006182 169.32,88.23 L163,85.49 L157.89,83.37 L150.83,80.12 L150.83,74 L151.29,73.51 C155.754203,68.6787482 158.226209,62.3379812 158.21,55.76 L158.21,49 C158.21,40.7157288 151.494271,34 143.21,34 C134.925729,34 128.21,40.7157288 128.21,49 L128.21,55.74 C128.18801,62.3239014 130.664654,68.6709713 135.14,73.5 L135.6,73.99 L135.6,80.11 L117.15,88.23 C115.787294,89.1006182 114.970587,90.6130377 114.99,92.23 C114.99,93.1799298 114.21993,93.95 113.27,93.95 C112.32007,93.95 111.549946,93.1799298 111.549946,92.23 C111.539619,89.389283 113.019946,86.7512645 115.45,85.28 L115.66,85.17 L132.18,77.9 L132.18,75.35 C127.410166,69.9443448 124.784887,62.9791748 124.8,55.77 L124.8,49 C124.8,38.8269152 133.046915,30.5800008 143.22,30.5800008 C153.393085,30.5800008 161.64,38.8269152 161.64,49 L161.64,55.74 C161.657898,62.9496789 159.03226,69.9158044 154.26,75.32 L154.26,77.89 L159.26,80.21 L164.31,82.31 L170.95,85.24 C173.390955,86.724733 174.872013,89.383042 174.850242,92.24 C174.845199,93.1575839 174.117032,93.9078162 173.2,93.94 L173.2,93.94 Z"
          id="Path"
        />
        <path
          d="M49.32,45.84 C48.4560178,45.8246277 47.7375634,45.1706152 47.6413034,44.3118745 C47.5450434,43.4531339 48.1008641,42.6562823 48.94,42.45 L52.45,41.66 L52.45,40 C48.35428,35.332241 46.103395,29.3298808 46.1199088,23.12 L46.1199088,17.34 C46.1199088,8.48687546 53.2968755,1.31 62.15,1.31 C71.0031245,1.31 78.1802736,8.48687546 78.1802736,17.34 L78.1802736,23.12 C78.1976573,26.9239015 77.3603682,30.6831587 75.73,34.12 C75.4691961,34.6737617 74.9327751,35.0463214 74.3228014,35.0973395 C73.7128278,35.1483575 73.1219711,34.8700831 72.7728014,34.3673394 C72.4236317,33.8645958 72.3691961,33.2137617 72.63,32.66 C74.0356623,29.6943909 74.7600205,26.4518628 74.75,23.17 L74.75,17.39 C74.75,10.4312122 69.1087878,4.79 62.15,4.79 C55.1912122,4.79 49.55,10.4312122 49.55,17.39 L49.55,23.17 C49.5368289,28.7507461 51.633611,34.1302246 55.42,38.23 L55.88,38.72 L55.88,44.47 L49.7,45.87 C49.5726073,45.8743627 49.44513,45.8642987 49.32,45.84 Z"
          id="Path"
        />
      </g>
    </g>
  </svg>
);

export default People;
