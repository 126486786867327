import { useCookieConsentContext } from "@use-cookie-consent/react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { color } from "../templates/ui";

const VideoWrapper = styled.div`
  position: relative;
  background-color: ${color.black.alt};
  max-width: 580px;
`;

const VideoContainer = styled.div`
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 0;
  position: relative;

  iframe,
  img {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    object-fit: cover;
  }
`;

const Video = ({ className, src }) => {
  const { consent } = useCookieConsentContext();
  const hasConsent =
    consent.necessary && consent.firstParty && consent.thirdParty;
  const [thumbnailUrl, setThumbnailUrl] = useState(null);

  useEffect(() => {
    // Only fetch thumbnail if user has not consented to marketing cookies
    if (hasConsent) return;

    // fetch video metadata via https://vimeo.com/api/oembed.json?url={src} for thumbnail
    (async () => {
      const res = await fetch(
        `https://vimeo.com/api/oembed.json?url=${src}`
      ).catch((err) => {
        console.error(err);
      });
      const data = await res.json();
      setThumbnailUrl(data.thumbnail_url);
    })();
  }, [src, hasConsent]);

  return (
    <VideoWrapper className={className}>
      <VideoContainer>
        {hasConsent ? (
          <iframe
            src={src}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
            title=" "
          />
        ) : (
          <img src={thumbnailUrl} alt="Video thumbnail" />
        )}
      </VideoContainer>
    </VideoWrapper>
  );
};

export default Video;
